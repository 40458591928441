import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, clearCart } from '../actions/cartActions';
import { Link } from 'react-router-dom';
import axios from 'axios';
const CheckoutPage = () => {
  const cartItems = useSelector((state) => state.auth.cartItems);
  const dispatch = useDispatch();

  const [customerName, setCustomerName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [specialInstruction, setSpecialInstruction] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cashOnDelivery');

  const handleRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  // const handlePlaceOrder = () => {
  //   const totalAmount = calculateTotalAmount();
  //   console.log(totalAmount);
  //   handleClearCart();

  //   // Extract the required data (quantity and ID) from cartItems
  //   const orderedItems = cartItems.map((item) => ({
  //     quantity: item.quantity,
  //     menuId: item.id,
  //   }));

  //   // Prepare the data to send to the backend
  //   const orderData = {
  //     CustomerName: customerName, 
  //     ContactInfo: contactInfo, 
  //     PaymentMethod: paymentMethod, 
  //     SpecialInstruction: specialInstruction, 
  //     deliveryAddress: deliveryAddress, 
  //     OrderedItems: orderedItems, 
  //     TotalAmount: totalAmount, 
  //   };

  //   // Send the order data to the backend
  //   axios
  //     .post('admin/createOrder', orderData) // Replace '/api/orders' with the actual backend endpoint for saving orders
  //     .then((response) => {
  //       // Handle the response from the backend
  //       console.log('Order placed successfully:', response.data);

  //       // Clear the cart after placing the order
  //       handleClearCart();

  //       // Perform necessary actions after placing the order
  //       // (e.g., redirect to a success page, show a payment confirmation message, etc.)
  //     })
  //     .catch((error) => {
  //       // Handle the error
  //       console.error('Error placing the order:', error);
  //     });
  // };
  const handlePlaceOrder = () => {
    const ordersByRestaurant = {};

    cartItems.forEach((item) => {
      const { RestaurantId, price, quantity } = item;
      const totalPrice = price * quantity;

      if (!ordersByRestaurant[RestaurantId]) {
        ordersByRestaurant[RestaurantId] = {
          RestaurantId,
          orderedItems: [],
          totalAmount: totalPrice,
        };
      } else {
        ordersByRestaurant[RestaurantId].totalAmount += totalPrice;
      }

      ordersByRestaurant[RestaurantId].orderedItems.push({
        quantity,
        menuId: item.id,
        totalPrice: totalPrice.toFixed(2),
      });
    });

    const orderData = Object.values(ordersByRestaurant).map((order) => ({
      CustomerName: customerName,
      ContactInfo: contactInfo,
      PaymentMethod: paymentMethod,
      SpecialInstruction: specialInstruction,
      deliveryAddress: deliveryAddress,
      OrderedItems: order.orderedItems,
      TotalAmount: order.totalAmount.toFixed(2), // Split total price for each restaurant
      RestaurantId: order.RestaurantId,
    }));

    axios
      .post('admin/createOrder', { orders: orderData })
      .then((response) => {
        console.log('Orders placed successfully:', response.data.orders);
        handleClearCart();
        // Perform necessary actions after placing the orders
      })
      .catch((error) => {
        console.error('Error placing the orders:', error);
      });
  };
  const calculateSubtotal = () => {
    let subtotal = 0;
    cartItems.forEach((item) => {
      subtotal += parseFloat(item.totalPrice);
    });
    return subtotal;
  };

  const calculateVAT = () => {
    const subtotal = calculateSubtotal();
    const vat = (subtotal * 15) / 100;
    return vat.toFixed(2);
  };

  const calculateTotalAmount = () => {
    const subtotal = calculateSubtotal();
    const vat = calculateVAT();
    const totalAmount = (subtotal + parseFloat(vat)).toFixed(2);
    return totalAmount;
  };

  return (
    <div className="container">
      <h3>
        <Link to="/cart">
          <i className="fas fa-arrow-left"></i> Back
        </Link>
      </h3>
      <h4>Checkout Page</h4>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div className="row">
          <div className="col-lg-6">
            <div className="item-list mt-4">
              <h4>Item List:</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img
                          src={`https://api.medafdelivery.com/${item?.picture}`}
                          alt={item.name}
                          style={{ width: '100px' }}
                        />
                        <h5>{item.name}</h5>
                      </td>
                      <td>{item.quantity}</td>
                      <td>{item.price} ETB</td>
                      <td>{item.totalPrice} ETB</td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleRemoveFromCart(item.id)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="payment-method mt-4">
                <h4>Payment Method:</h4>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    id="cashOnDelivery"
                    value="cashOnDelivery"
                    checked={paymentMethod === 'cashOnDelivery'}
                    onChange={() => setPaymentMethod('cashOnDelivery')}
                  />
                  <label className="form-check-label" htmlFor="cashOnDelivery">
                    Cash on Delivery
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    id="telebirr"
                    value="telebirr"
                    checked={paymentMethod === 'telebirr'}
                    onChange={() => setPaymentMethod('telebirr')}
                  />
                  <label className="form-check-label" htmlFor="telebirr">
                    Telebirr
                  </label>
                </div>
              </div>
              <div className="order-summary mt-4">
                <h4>Order Summary:</h4>
                <div>
                  <span>Subtotal:</span>
                  <span>{calculateSubtotal()} ETB</span>
                </div>
                <div>
                  <span>VAT (15%):</span>
                  <span>{calculateVAT()} ETB</span>
                </div>
                <div>
                  <span>Total Amount to Pay:</span>
                  <span>{calculateTotalAmount()} ETB</span>
                </div>
              </div>
              <div className="checkout-buttons mt-4">
                <Link to="/cart" className="btn btn-secondary mr-2">
                  Go Back to Cart
                </Link>
                <button className="btn btn-primary" onClick={handlePlaceOrder}>
                  Place Order
                </button>
              </div>
            </div>

          </div>
          <div className="col-lg-6">
            <div className="customer-information mt-4">
              <h4>Customer Information:</h4>
              <form>
                <div className="form-group">
                  <label htmlFor="customerName">Customer Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customerName"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactInfo">Contact Info:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactInfo"
                    value={contactInfo}
                    onChange={(e) => setContactInfo(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="specialInstruction">Special Instruction:</label>
                  <textarea
                    className="form-control"
                    id="specialInstruction"
                    rows="3"
                    value={specialInstruction}
                    onChange={(e) => setSpecialInstruction(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="deliveryAddress">Delivery Address:</label>
                  <textarea
                    className="form-control"
                    id="deliveryAddress"
                    rows="3"
                    value={deliveryAddress}
                    onChange={(e) => setDeliveryAddress(e.target.value)}
                  ></textarea>
                </div>
              </form>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutPage;
