import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Alert,
  FormGroup,
  Container,
  Col,
  Row,
  Card,
  Table,
  Modal
} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Modal.css';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
const Driver = () => {
  const [email, setEmail] = useState("");
  const [cartype, setCarType] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(null);
  const [vehicleType, setVehicleType] = useState("");
  const [plateNumber, setPlateNumber] = useState("");
  const [creditAmount, setCreditAmount] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [message, setMessage] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const [showModal, setShowModal] = useState(false);
  const accessToken = useSelector((state) => state.auth.user);
  const history = useHistory();
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (email === "" || fname === "") {
      toast.error('Failed to Add Driver. Please try again later.', {
        position: 'top-right',
        autoClose: 3000,
      });
    } else {
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("phone", phone);
      formData.append("photo", photo);
      formData.append("vehicleType", vehicleType);
      formData.append("plateNumber", plateNumber);
      formData.append("creditAmount", creditAmount);
      await axios
        .post("admin/newDriver", formData)
        .then((res) => {
          toast.success('Driver Successfully Added!', {
            position: 'top-right',
            autoClose: 3000,
          });
          setEmail("");
          setPassword("");
          setFname("");
          setLname("");
          setPhone("");
          setPhoto("");
          setVehicleType("");
          setPlateNumber("");
          getDrivers();
        });
    }
  };

  const getDrivers = async () => {
    try {
      const apiUrl = "https://api.medafdelivery.com/api/admin/listDriver";
      // Check if accessToken is available
      if (!accessToken) {
        throw new Error("Access token is not available.");
      }
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': accessToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        // Handle non-successful responses here, e.g., if the status code is not in the 200 range.
        throw new Error("Failed to fetch Drivers. Please check your network connection.");
      }

      const data = await response.json();
      setDrivers(data);
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: false,
      });
    }
  }
  const handleDelete = async (id) => {
    try {
      console.log(id);
      const response = await axios.post(`https://api.medafdelivery.com/api/admin/deleteDriver/${id}`);

      // Display a success toast
      toast.success('Driver deleted successfully!', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });

      console.log(response.data);
      getDrivers();
    } catch (error) {
      console.error("Error deleting driver:", error);

      // Display an error toast
      toast.error('Failed to delete driver. Please try again later.', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });
    }
  };
  const handleVerify = async (id) => {
    try {
      console.log(id);
      const response = await axios.post(`https://api.medafdelivery.com/api/admin/verifyDriver/${id}`);

      if (response.data.isVerified) {
        toast.success('Driver Verify successfully!', {
          position: 'top-right',
          autoClose: 3000,
        });
      } else {
        toast.success('Driver UnVerify successfully!', {
          position: 'top-right',
          autoClose: 3000,
        });
      }


      console.log(response.data);
      getDrivers();
    } catch (error) {
      console.error("Error Verifying driver:", error);
      toast.error('Failed to Verify driver. Please try again later.', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getDrivers();
  }, []);
  
  return (
    <>

      <Button onClick={handleShowModal}>Create Driver</Button>
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-custom">
        <Modal.Header closeButton>
          <Modal.Title>Add Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            method="POST"
            style={{ width: "100%" }}
            encType="multipart/form-data"
          >

            <ToastContainer />

            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="fname"
                type="text"
                placeholder="Enter driver fname"
                value={fname}
                required
                onChange={(e) => setFname(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="lname"
                type="text"
                placeholder="Enter driver lname"
                value={lname}
                required
                onChange={(e) => setLname(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="phone"
                type="text"
                placeholder="Enter driver phone number"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>


            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="email"
                type="email"
                placeholder="Enter driver email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password </Form.Label>
              <Form.Control
                className="form-control-lg"
                name="password"
                type="password"
                placeholder="Enter password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <FormGroup>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                onChange={(e) => setPhoto(e.target.files[0])}
                type="file"
                name="photo"
              />
            </FormGroup>
            <Form.Group>
              <Form.Label>Vehicle Type</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="vehicleType"
                type="text"
                placeholder="Enter vehicle type"
                value={vehicleType}
                required
                onChange={(e) => setVehicleType(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plate Number</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="plateNumber"
                type="text"
                placeholder="Enter plateNumber"
                value={plateNumber}
                required
                onChange={(e) => setPlateNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Credit Amount</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="creditAmount"
                type="text"
                placeholder="Enter Credit Amount"
                value={creditAmount}
                required
                onChange={(e) => setCreditAmount(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="m-3">
              <Button type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Lists of Drivers</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">ID</th>
                  <th className="border-0">Name</th>
                  {/* <th className="border-0">email</th> */}
                  <th className="border-0">phone</th>
                  <th className="border-0">Image</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {drivers.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      {item.fname} {item.lName}
                    </td>
                    {/* <td>{item.email}</td> */}
                    <td>{item.phone}</td>

                    <td>
                      <img
                        src={`https://api.medafdelivery.com/${item.photo}`}
                        alt="Item"
                        style={{ width: "100px" }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.isVerified}
                        onChange={() => handleVerify(item.id)}
                        style={{ width: '20px', height: '20px' }}
                      />
                      <br />
                      <Button
                        color="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        x
                      </Button>


                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Driver;
