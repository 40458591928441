import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../actions/cartActions';
import { Link } from 'react-router-dom';

const CartPage = () => {
  const cartItems = useSelector((state) => state.auth.cartItems);
  const dispatch = useDispatch();

  const handleRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  return (
    <div className="container">
    <h3>
        <Link to="/admin/create-order">
          <i className="fas fa-arrow-left"></i> Back
        </Link>
      </h3>
      <h4>Cart Page</h4>
    
    {cartItems.length === 0 ? (
      <p>Your cart is empty.</p>
    ) : (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item) => (
              <tr key={item.id}>
                <td>
                  <img src={`https://api.medafdelivery.com/${item?.picture}`} alt={item.name} style={{ width: '100px' }} />
                  <h5>{item.name}</h5>
                </td>
                <td>{item.quantity}</td>
                <td>{item.price} ETB</td>
                <td>{item.totalPrice} ETB</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleRemoveFromCart(item.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Link to="/checkout" className="btn btn-primary">Checkout</Link>
      </div>
    )}
  </div>
  );
};

export default CartPage;
