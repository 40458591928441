import React, { useState, useEffect } from 'react';
import { Card, Row } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DeliveredOrders = () => {
  const [deliveredOrders, setdeliveredOrders] = useState([]);

  useEffect(() => {
    fetchdeliveredOrders();
  }, []);

  const formatUpdatedAt = (updatedAt) => {
    try {
      // Create a new Date object from the updatedAt date string
      const date = new Date(updatedAt);

      // Get the date components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // Format the date as "yyyy-MM-dd HH:mm:ss"
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } catch (error) {
      console.error("Error formatting updatedAt:", error);
      return "Error";
    }
  };

  const fetchdeliveredOrders = async () => {
    try {
      const response = await axios.get("admin/getDeliveredOrder");
      const menuItemsResponse = await axios.get("admin/listItems");

      const deliveredOrdersData = response.data;
      const menuItemsData = menuItemsResponse.data;

      setdeliveredOrders(
        deliveredOrdersData.map(order => ({
          ...order,
          menuItems: order.OrderedItems.map(orderItem => {
            const menuItem = menuItemsData.find(menuItem => menuItem.id === orderItem.menuId);
            return menuItem ? { ...orderItem, menuItem } : null;
          }).filter(Boolean)
        }))
      );
      // toast.success('Delivered orders have been successfully fetched!', {
      //   position: 'top-right',
      //   autoClose: 3000, // Adjust the duration as needed
      // });
    } catch (error) {
      console.error("Error fetching delivered orders and menu items:", error);
      toast.error('Failed to fetch delivered orders. Please try again later.', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });
    }
  };

  return (
    <>
      <strong># Deliverd Orders</strong>
      <br /><br />

      <Row>
        {deliveredOrders &&
          deliveredOrders.map(order => (
            <div className="col-xl-6 col-sm-6 mb-6" key={order.id}>
              <Card className="o-hidden h-100">
                <Card.Header>
                  <div className="d-flex justify-content-between">
                    <p><strong>#Order ID:</strong> {order.OrderTrackingNumber}</p>
                    <p><strong>Order Date:</strong> {formatUpdatedAt(order.updatedAt)}</p>
                  </div>
                </Card.Header>
                <Card.Body>
                  {order.menuItems.map(orderItem => (
                    <div key={orderItem.menuId}>
                      <div className="d-flex justify-content-between">
                        <img src={`https://api.medafdelivery.com/${orderItem.menuItem.picture}`} width="50px" className="rounded-circle" alt="img.." />
                        <p>{orderItem.quantity} x {orderItem.menuItem?.name}</p>
                        <p><strong>Payment: </strong>{order.PaymentMethod}</p>
                      </div>
                    </div>
                  ))}
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p><strong>Customer Name:</strong> {order.CustomerName}</p>
                      <p><strong>Order Price:</strong> ETB {order.TotalAmount}</p>
                      <p><strong>Customer Num:</strong> {order.ContactInfo}</p>
                      <p><strong>Delivery Address:</strong> {order.deliveryAdress}</p>
                      <p><strong>Driver Name:</strong> {order.DriverId} </p>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </div>
          ))}
      </Row>
    </>
  );
};

export default DeliveredOrders;
