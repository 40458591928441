import React from 'react'
import { Button, Form } from 'react-bootstrap'

const Account = () => {
    return (
        <Form method='POST' style={{ width: '80%' }} >
            <h1 style={{ fontSize: '2em', padding: '15px' }}>Edit Profile</h1>

            <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control className='form-control-lg' name="name" type="text" value="admin" placeholder="Admin" required />
            </Form.Group>
            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control className='form-control-lg' name="email" type="text" value="admin@gmail.com" placeholder="admin@gmail.com" required />
            </Form.Group>
            <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control className='form-control-lg' name="password" type="text" value='*************' placeholder="*********" required />
            </Form.Group>
            <Form.Group className='m-3'>
                <Button type="submit">
                    Submit
                </Button>
            </Form.Group>
        </Form>
    )
}

export default Account
