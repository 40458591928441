import React, { useState, useEffect } from 'react';
import { Card, Row } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Pending = () => {
  const [pendingOrders, setPendingOrders] = useState([]);

  useEffect(() => {
    fetchPendingOrders();
  }, []);

  const fetchPendingOrders = async () => {
    try {
      const response = await axios.get("admin/getPendingOrders");
      const menuItemsResponse = await axios.get("admin/listItems");
  
      const pendingOrdersData = response.data;
      console.log('Pending Order Data: ', pendingOrdersData)

      const menuItemsData = menuItemsResponse.data;
  
      setPendingOrders(
        pendingOrdersData.map(order => ({
          ...order,
          menuItems: order.OrderedItems.map(orderItem => {
            const menuItem = menuItemsData.find(menuItem => menuItem.id === orderItem.menuId);
            return menuItem ? { ...orderItem, menuItem } : null;
          }).filter(Boolean)
        }))
      );
  
      toast.success('Pending orders have been successfully fetched!', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });
    } catch (error) {
      toast.error('Failed to fetch pending orders. Please check the error there.', error, {
        position: 'top-right',
        autoClose: false, // Adjust the duration as needed
      });
      console.error("Error fetching pending orders and menu items:", error);
    }
  };
  

  const handleAcceptOrder = async (orderId) => {
    try {
      await axios.post(`admin/orders/${orderId}/accepted`);
      const updatedOrders = pendingOrders.map(order => {
        if (order.id === orderId) {
          return {
            ...order,
            status: "Accepted"
          };
        }
        return order;
      });
      toast.success('Order Successfully Accepted!', {
        position: 'top-right',
        autoClose: 3000,
      });
      setPendingOrders(updatedOrders);
      fetchPendingOrders();
    } catch (error) {
      toast.error('Failed to Accept Order. Please try again later.', {
        position: 'top-right',
        autoClose: 3000,
      });

    }
  };

  const handleRejectOrder = async (orderId) => {
    try {
      await axios.post(`admin/orders/${orderId}/cancelled`);
      const updatedOrders = pendingOrders.map(order => {
        if (order.id === orderId) {
          return {
            ...order,
            status: "canceled"
          };
        }
        return order;
      });
      toast.success('Order Successfully Accepted!', {
        position: 'top-right',
        autoClose: 3000,
      });
      setPendingOrders(updatedOrders);
      fetchPendingOrders();
    } catch (error) {
      toast.error('Failed to Accept Order. Please try again later.', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  const formatUpdatedAt = (updatedAt) => {
    try {
      // Create a new Date object from the updatedAt date string
      const date = new Date(updatedAt);

      // Get the date components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // Format the date as "yyyy-MM-dd HH:mm:ss"
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } catch (error) {
      console.error("Error formatting updatedAt:", error);
      return "Error";
    }
  };


  return (
    <>
      <strong># Pending Orders</strong>
      <br /><br />

      <Row>
        {pendingOrders.map(order => (
          <div className="col-xl-6 col-sm-6 mb-6" key={order.id}>
            <Card className="o-hidden h-100">
              <Card.Header>
                <div className="d-flex justify-content-between">
                  <p><strong>#Order ID:</strong> {order.OrderTrackingNumber}</p>
                  <p><strong>Order Date:</strong> {formatUpdatedAt(order.updatedAt)}</p>
                </div>
              </Card.Header>
              <Card.Body>
                {order.menuItems && order.menuItems.map(orderItem => (
                  <div key={orderItem.menuId}>
                    <div className="d-flex justify-content-between">
                      <img src={`https://api.medafdelivery.com/${orderItem.menuItem.picture}`} width="50px" className="rounded-circle" alt="img.." />
                      <p>{orderItem.quantity} x {orderItem.menuItem?.name}  </p>
                      <p> <b>From </b> {orderItem.menuItem.Restaurant?.name} <br/>
                      <b>Address </b> {orderItem.menuItem.Restaurant?.address} <br/>
                      <b>Phone </b> {orderItem.menuItem.Restaurant?.phone}</p>
                      <p><strong>Payment: </strong>{order?.PaymentMethod}</p>
                    </div>
                  </div>
                ))}
              </Card.Body>
              <Card.Footer>
                <div className="d-flex justify-content-between">
                  <div>
                  <p><strong>Customer Name:</strong> {order.CustomerName}</p>
                    <p><strong>Order Price:</strong> ETB {order.TotalAmount}</p>
                    <p><strong>Customer Num:</strong> {order.ContactInfo}</p>
                  </div>
                  <div className="d-flex justify-content-start ms-2 align-items-sm-center">
                  
                    <button className="btn btn-sm btn-danger" onClick={() => handleRejectOrder(order.id)}>Reject</button>
                    <button className="btn btn-sm btn-success" onClick={() => handleAcceptOrder(order.id)}>Accept</button>
                  </div>
                </div>
              </Card.Footer>
            </Card>
          </div>
        ))}
      </Row>
    </>
  );
};

export default Pending;
