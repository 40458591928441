import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Alert,
  FormGroup,
  Container,
  Col,
  Row,
  Card,
  Table,
  Modal
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Modal.css';

const Blog = () => {
  const [title, setTitle] = useState("");
  const [headline, setHeadline] = useState("");
  const [full_description, setFullDescription] = useState("");
  const [blogImg, setBlogImg] = useState(null);

  const [blog, setBlog] = useState([]);
  const [message, setMessage] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const getClick = () => {
    history.push("/admin/blog");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (title === "" || headline === "") {
      toast.error('Failed to add Blog. Please fill in all required fields.', {
        position: 'top-right',
        autoClose: 3000,
      });
    } else {
      formData.append("photo", blogImg);
      formData.append("title", title);
      formData.append("headline", headline);
      formData.append("full_description", full_description);

      axios
        .post("admin/newBlog", formData)
        .then((res) => {
          toast.success('Blog Successfully Added!', {
            position: 'top-right',
            autoClose: 3000,
          });
          setTitle("");
          setHeadline("");
          setFullDescription("");
          setBlogImg(null);
          getBlog();
        })
        .catch((error) => {
          toast.error('Failed to add blog. Please check your network connection.', {
            position: 'top-right',
            autoClose: false, // Keep the toast open until manually closed
          });
        });
    }
  };
  const handleDelete = async (id) => {
    await axios
      .post(`admin/deleteBlog/${id}`)
      .then((res) => {
        toast.success('Blog Successfully Deleted!', {
          position: 'top-right',
          autoClose: 3000,
        });
        getBlog();
      })
      .catch((error) => {
        toast.error('Failed to delete Blog. Please check your network connection.', {
          position: 'top-right',
          autoClose: false, // Keep the toast open until manually closed
        });
      });
  };

  const getBlog = async () => {
    await axios
      .get("admin/listBlog")
      .then((res) => {
        setBlog(res.data);
      })
      .catch((error) => {
        toast.error('Failed to fetch blogs. Please check your network connection.', {
          position: 'top-right',
          autoClose: false, // Keep the toast open until manually closed
        });
      });
  };

  useEffect(() => {
    getBlog();
  }, []);
  return (
    <>
      <Button onClick={handleShowModal}>Create Blog</Button>
      <Modal show={showModal} onHide={handleCloseModal} style={{top: '-20%'}}>
        <Modal.Header closeButton>
          <Modal.Title>Add Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            method="POST"
            style={{ width: "100%" }}
            encType="multipart/form-data"
          >
           
            <ToastContainer />
            <Form.Group>
              <Form.Label>Blog Title</Form.Label>
              <Form.Control
                className="form-control-lg"
                id="cID"
                name="title"
                type="text"
                placeholder="Enter Blog Title"
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Blog Headline</Form.Label>
              <Form.Control
                className="form-control-lg"
                id="cID"
                name="headline"
                type="text"
                placeholder="Enter Blog Headline"
                value={headline}
                required
                onChange={(e) => setHeadline(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Blog Full Description</Form.Label>
              <Form.Control
                className="form-control-lg"
                id="cID"
                name="full_description"
                type="text"
                placeholder="Enter Blog Description"
                value={full_description}
                required
                onChange={(e) => setFullDescription(e.target.value)}
              />
            </Form.Group>
            <FormGroup>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                onChange={(e) => setBlogImg(e.target.files[0])}
                type="file"
                name="photo"
              />
            </FormGroup>

            <Form.Group className="m-3">
              <Button type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Lists of Blog</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">ID</th>
                  <th className="border-0">Title</th>
                  <th className="border-0">Headline</th>
                  <th className="border-0">Description</th>
                  <th className="border-0">Image</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {blog.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.title}</td>
                    <td>{item.headline.slice(0, 10)}</td>
                    <td>{item.full_description.slice(0, 10)}</td>
                    <td>
                      <img
                        src={`https://api.medafdelivery.com/${item.photo}`}
                        alt="Item"
                        style={{ width: "100px" }}
                      />
                    </td>
                    <td>
                      <Button
                        color="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          
        </Card>
      </Col>
    </>
  );
};

export default Blog;
