import React, { useState, useEffect } from 'react';
import { Card, Image, Button } from 'react-bootstrap';
import { addToCart } from '../actions/cartActions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CreateOrder.css';

const CreateOrder = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const dispatch = useDispatch();

  // Fetch categories, menu items, and restaurants from the backend
  useEffect(() => {
    fetchCategories();
    fetchMenuItems();
    fetchRestaurants();
  }, []);

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
  };

  const fetchCategories = async () => {
    try {
      const res = await axios.get("admin/listCategory");
      setCategories(res.data);
    } catch (error) {
      toast.error('Failed to fetch categories. Please check your network connection.', {
        position: 'top-right',
        autoClose: false, 
      });
    }
  };

  const fetchMenuItems = async () => {
    try {
      const res = await axios.get("admin/listItems");
      setMenuItems(res.data);
    } catch (error) {
      toast.error('Failed to fetch menu items. Please check your network connection.', {
        position: 'top-right',
        autoClose: false, 
      });
    }
  };

  const fetchRestaurants = async () => {
    try {
      const res = await axios.get("admin/listRestaurant");
      setRestaurants(res.data);
    } catch (error) {
      toast.error('Failed to fetch restaurants. Please check your network connection.', {
        position: 'top-right',
        autoClose: false, 
      });
    }
  };

  const filterMenuItems = async (restaurantId) => {
    try {
      console.log(restaurantId);
      const res = await axios.get(`admin/getMenuByRes/${restaurantId}`)
        .then((res) => {

          if (selectedCategory) {
            const filteredItems = res.data.filter((item) => item.CategoryId === selectedCategory);
            setFilteredMenuItems(filteredItems);
          } else {
            setFilteredMenuItems(res.data);
          }

        });

    } catch (error) {
      console.error('Error filtering menu items:', error);
      toast.error('Failed to filter menu items. Please check your network connection.', {
        position: 'top-right',
        autoClose: false, 
      });
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      const filteredItems = menuItems.filter((item) => item.CategoryId === selectedCategory);
      setFilteredMenuItems(filteredItems);
    } else if (selectedCategory && selectedRestaurant) {
      const filteredItems = menuItems.filter((item) => item.CategoryId === selectedCategory && item.RestaurantId === selectedRestaurant);
      setFilteredMenuItems(filteredItems);
    } else {
      setFilteredMenuItems(menuItems);
    }
  }, [selectedCategory, selectedRestaurant, menuItems]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.id);
  };

  const handleRestaurantChange = (id) => {
    filterMenuItems(id);
  };

  return (
    <div>
      <div className="restaurant-select">
        <select value={selectedRestaurant} onChange={(e) => handleRestaurantChange(e.target.value)}>
          <option value="">Select an Restaurant</option>
          {restaurants.map((restaurant) => (
            <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
          ))}
        </select>
      </div>
      <div className="category-list">
        {categories.map((category) => (
          <Card
            key={category.id}
            className={`category-item ${selectedCategory === category.id ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            <div className="category-image-container">
              <img src={`https://api.medafdelivery.com/${category.photo}`} alt={category.name} className="category-image" />
              <div className="category-title">{category.name}</div>
            </div>
          </Card>
        ))}
      </div>

      <div className="menu-items">
        {filteredMenuItems.map((item) => (
          <Card key={item.id} className="menu-item">
            <div className="menu-image-container">
              <img src={`https://api.medafdelivery.com/${item.picture}`} alt={item.name} className="menu-image" />
            </div>
            <Card.Body>
              <Card.Title>{item.name}</Card.Title>
              <div className="menu-details">
                <div className="menu-price">{item.price} ETB</div>
                <Button
                  variant="primary"
                  className="add-to-cart-btn"
                  onClick={() => handleAddToCart(item)}
                >
                  Add to Cart
                  {/* <i className="fas fa-ellipsis-v"></i> */}
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateOrder;
