import React, { useState, useEffect } from 'react';
import Pending from './Pending';
import ActiveOrders from './ActiveOrder';
import { Tab, Tabs } from 'react-bootstrap';
import Maps from './Maps';
import BeingPreparedOrder from './BeingPreparedOrder';
import OutForDeliveryOrder from './OutForDeliveryOrder';
import OnTheWay from './OnTheWay';
import DeliveredOrders from './DeliveredOrder';
import RejectedOrders from './RejectedOrder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Order = () => {
  const [key, setKey] = useState('pending');
  const [refreshKey, setRefreshKey] = useState(0);

  // Reset the refresh key whenever a tab is changed
  useEffect(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, [key]);

  return (
    <>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        id="controlled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="pending" title="Pending">
          <Pending key={refreshKey} />
        </Tab>
        <Tab eventKey="active" title="Accepted">
          <ActiveOrders key={refreshKey} />
        </Tab>
        <Tab eventKey="being-prepared" title="Being Prepared">
          <BeingPreparedOrder key={refreshKey} />
        </Tab>
        <Tab eventKey="out-for-deliver" title="Ready for Delivery">
          <OutForDeliveryOrder key={refreshKey} />
        </Tab>
        <Tab eventKey="on-the-way" title="On The Way">
          <OnTheWay key={refreshKey} />
        </Tab>
        <Tab eventKey="deliverd" title="Deliverd">
          <DeliveredOrders key={refreshKey} />
        </Tab>
        <Tab eventKey="rejected" title="Rejected">
          <RejectedOrders key={refreshKey} />
        </Tab>
        <Tab eventKey="monitor" title="Monitor">
          <Maps key={refreshKey} />
        </Tab>
      </Tabs>
      <ToastContainer />
    </>
  );
};

export default Order;

