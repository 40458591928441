import React, { useState } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import { useSelector } from 'react-redux';
import LoginPage from 'views/LoginPage';
import CartPage from 'views/CartPage';
import CheckoutPage from 'views/CheckoutPage';
import AddMenu from 'views/AddMenu';

const App = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  // const usr = localStorage.getItem('token');
  return (
    <Switch>
      {isAuthenticated? (<>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/cart" component={CartPage} />
        <Route path="/checkout" component={CheckoutPage} />
        <Route path="/add-menu/:restaurantId" component={AddMenu} />
        <Redirect from="/" to="/admin/dashboard" />
        </>
      ):(
        <>
            <LoginPage />
        </>
      )}
      
    </Switch>
  )
}

export default App
