import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import markerImage from "../assets/img/medaf_driver.png"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Maps({ setSelectedDriver }) {
  const mapRef = useRef(null);
  const searchInputRef = useRef(null);
  const [drivers, setDrivers] = useState([]);
  const [map, setMap] = useState(null);
  const markersRef = useRef([]);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    let google = window.google;
    let lat = "8.9806";
    let lng = "38.7578";
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT, // Adjust the position as needed
      },
    };

    const mapInstance = new google.maps.Map(mapRef.current, mapOptions);
    setMap(mapInstance);


  }, []);

  const getDrivers = async () => {
    try {
      const response = await axios.get("admin/listDriver");
      const selectedColumns = response.data.map((driver) => ({
        lat: parseFloat(driver.latitude),
        lng: parseFloat(driver.longitude),
        title: `${driver.fname} ${driver.lName}`,
        type: driver.vehicleType,
        phone: driver.phone,
        ID: driver.id,
        icon: markerImage,
      }));
      setDrivers(selectedColumns);
    } catch (error) {
      toast.error("Failed to fetch driver data. Please try again later.", {
        position: 'top-right',
        autoClose: false,
      });
      console.error("Error fetching driver data:", error);
    }
  };


  useEffect(() => {
    getDrivers();
    const interval = setInterval(() => {
      getDrivers();
    }, 20000); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (map && drivers) {
      markersRef.current.forEach((marker) => {
        marker.setMap(null);
      });

      markersRef.current = [];

      drivers.forEach((location) => {
        const marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(location.lat, location.lng),
          map: map,
          animation: window.google.maps.Animation.DROP,
          title: location.title,
          icon: {
            url: location.icon,
            scaledSize: new window.google.maps.Size(60, 60),
          },
        });

        const contentString = `<div class="info-window-content">
          <h3>${location.title}</h3>
          <p>${location.phone}</p>
          <p>${location.type}</p>
          <p>${location.ID}</p>
        </div>`;

        const infowindow = new window.google.maps.InfoWindow({
          content: contentString,
        });
        try {
          window.google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
            try {
              setSelectedDriver(location);
            } catch (error) {
              // toast.success("Driver Selected");
            }
          });
        } catch (error) {
          toast.error("An error occurred while setting the driver information.");
        }

        markersRef.current.push(marker);
      });
    }
  }, [map, drivers, setSelectedDriver]);

  return (
    <>
      <div className="map-container" style={{ maxHeight: '600px', maxWidth: '1500px' }}>

        {/* <input
          type="text"
          placeholder="Search places..."
          ref={searchInputRef}
          style={{ marginBottom: '10px' }}
        /> */}
        <div id="map" ref={mapRef}></div>
      </div>
    </>
  );
}

export default Maps;