import React from "react";


function Organization() {
  return (
    <>
      <div>Organization Will be coming soon</div>
    </>
  );
}

export default Organization;
