import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Form,
    Button,
    Alert,
    FormGroup,
    Container,
    Col,
    Row,
    Card,
    Table,
    FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
const SingleMenu = ({ rId }) => {


    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [photo, setPhoto] = useState(null);
    const [price, setPrice] = useState("");
    const [isFeatured, setIsFeatured] = useState(Boolean);
    const [menus, setMenus] = useState([]);
    const [restMenu, setRestMenu] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [restaurant, setRestaurant] = useState([]);
    const [menuData, setMenuData] = useState([]);
    const [restaurantId, setRestaurantId] = useState("");
    const [resMenu, setResMenu] = useState([]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (name === "" || price === "") {
            toast.error('Failed to Add Menu. Please try again later.', {
                position: 'top-right',
                autoClose: 3000,
            });
        } else {
            formData.append("name", name)
            formData.append("description", description)
            formData.append("photo", photo);
            formData.append("price", price)
            formData.append("isFeatured", isFeatured)
            formData.append("categoryId", categoryId)
            formData.append("restaurantId", rId)

            console.log(formData);

            await axios
                .post("admin/newItems", formData)
                .then((res) => {
                    setName("");
                    setDescription("");
                    setPhoto("");
                    setPrice("");
                    setIsFeatured(false);

                    console.log("Data submitted");
                    getResMenu();

                });
                toast.success('Menu Successfully Added!', {
                    position: 'top-right',
                    autoClose: 3000,
                });
        }
    };

    const handleDelete = async (id) => {
        try {
          const response = await axios.post(`admin/deleteItems/${id}`);
          console.log(response.data);
          getMenus();
      
          // Notify success
          toast.success('Menu item deleted successfully!', {
            position: 'top-right',
            autoClose: 3000,
          });
        } catch (error) {
          console.error("Error deleting menu item:", error);
      
          // Notify error
          toast.error('Failed to delete menu item. Please try again later.', {
            position: 'top-right',
            autoClose: 3000,
          });
        }
      };


const getResMenu = async () => {
  try {
    const response = await axios.get(`admin/getMenuByRes/${rId}`);
    setResMenu(response.data);
  } catch (error) {
    console.error(error);

    // Notify error
    toast.error('Failed to fetch restaurant menu. Please try again later.', {
      position: 'top-right',
      autoClose: 3000,
    });
  }
};


useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get("admin/listCategory");
        setCategory(response.data);
      } catch (error) {
        console.error(error);
  
        // Notify error
        toast.error('Failed to fetch categories. Please try again later.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    };
  
    const getRestaurant = async () => {
      try {
        const response = await axios.get("admin/listRestaurant");
        setRestaurant(response.data);
      } catch (error) {
        console.error(error);
  
        // Notify error
        toast.error('Failed to fetch restaurants. Please try again later.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    };
  
    getCategory();
    getRestaurant();
    getResMenu();
  
  }, []);
    return (
        <>
            <Form
                onSubmit={handleSubmit}
                method="POST"
                style={{ width: "80%" }}
                encType="multipart/form-data"
            >
                <ToastContainer />
                <h1 style={{ fontSize: "2em", padding: "15px" }}>Add Item for This Organization</h1>
                

                <Form.Group>
                    <Form.Label>Food Category </Form.Label>
                    <Form.Select
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.target.value)}
                        aria-label="select category"
                        size="lg"
                    >
                        <option>Select Category</option>
                        {category.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Select Restaurant </Form.Label>
                    <Form.Select
                        value={categoryId}
                        onChange={(e) => setRestaurantId(e.target.value)}
                        aria-label="select category"
                        size="lg"
                    >
                        <option>Select Category</option>
                        {restaurant.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group> */}
                <Form.Group>
                    <Form.Label>Food Name</Form.Label>
                    <Form.Control
                        className="form-control-lg"
                        name="name"
                        type="text"
                        placeholder="Enter food name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Description </Form.Label>
                    <Form.Control
                        className="form-control-lg"
                        name="description"
                        type="text"
                        placeholder="Enter Description"
                        value={description}
                        required
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>
                <FormGroup>
                    <Form.Label>Upload Image</Form.Label>
                    <Form.Control
                        onChange={(e) => setPhoto(e.target.files[0])}
                        type="file"
                        name="photo"
                    />
                </FormGroup>
                <Form.Group>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                        className="form-control-lg"
                        name="price"
                        type="text"
                        placeholder="Enter price"
                        value={price}
                        required
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>isFeatured</Form.Label>
                    <Form.Control
                        className="form-control-sm"
                        name="featured"
                        type="checkbox"
                        placeholder="Is featured"
                        required
                        onChange={(e) => setIsFeatured(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="m-3">
                    <Button type="submit" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Form.Group>
            </Form>
            {resMenu ? (
                <Col md="12">
                    <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                            <Card.Title as="h4">Lists of Menus</Card.Title>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                            <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">ID</th>
                                        <th className="border-0">Name</th>
                                        <th className="border-0">Category</th>
                                        <th className="border-0">Price</th>
                                        <th className="border-0">Image</th>
                                        <th className="border-0">Is Featured</th>
                                        <th className="border-0">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {resMenu?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item?.Category?.name || 'N/A'}</td>
                                            <td>{item.price} ETB</td>
                                            <td>
                                                <img
                                                    src={`https://api.medafdelivery.com/${item.picture}`}
                                                    alt="Item"
                                                    style={{ width: '100px' }}
                                                />
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <FormControl
                                                        type="checkbox"
                                                        defaultChecked={item?.isFeatured}
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <Button
                                                    color="danger"
                                                    onClick={() => handleDelete(item.id)}
                                                >
                                                    x
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            ) : (
                <p>No menu Found...</p>
            )}
            <ToastContainer />
        </>
    );
};

export default SingleMenu;
