import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contacts = () => {

  const [contacts, setContacts] = useState([]);
  const accessToken = useSelector((state) => state.auth.user);
  const getContacts = async () => {
    try {
      const apiUrl = "https://api.medafdelivery.com/api/admin/listContact";
      if (!accessToken) {
        throw new Error("Access token is not available.");
      }
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': accessToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Contacts. Please check your network connection.");
      }

      const data = await response.json();
      setContacts(data);
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: false,
      });
    }
  }
  useEffect(() => {
    getContacts();
  }, []);

  const handleDelete = async (id) => {
    try {
      console.log(id);
      const response = await axios.post(`https://api.medafdelivery.com/api/admin/deleteContact/${id}`);

      // Display a success toast
      toast.success('Partner deleted successfully!', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });

      console.log(response.data);
      getDrivers();
    } catch (error) {
      console.error("Error deleting Partner:", error);

      // Display an error toast
      toast.error('Failed to delete Partner. Please try again later.', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });
    }
  };

  return (
    <>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Lists of Contacts</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">ID</th>
                  <th className="border-0">Full Name</th>
                  <th className="border-0">Phone</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Message</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((item, index) => (
                  <tr key={item.id}> 
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.message}</td>
                    <td>
                      <Button
                        color="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

export default Contacts
