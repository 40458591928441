import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './authReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Specify the state properties to persist
};

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers here if needed
});

export default persistReducer(persistConfig, rootReducer);
