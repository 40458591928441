import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Alert,
  FormGroup,
  Container,
  Col,
  Row,
  Card,
  Table,
  Modal
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Modal.css';

const Category = () => {
  const [categoryName, setCategoryName] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");
  const [categoryImg, setCategoryImg] = useState(null);
  const [category, setCategory] = useState([]);
  const [message, setMessage] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [restaurant, setRestaurant] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");



  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const getClick = () => {
    history.push("/admin/category");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (categoryName === "" || categoryDesc === "") {
      toast.error('Failed to add category. Please fill in all required fields.', {
        position: 'top-right',
        autoClose: 3000,
      });
    } else {
      formData.append("photo", categoryImg);
      formData.append("name", categoryName);
      formData.append("description", categoryDesc);
      formData.append("restaurantId", restaurantId)

      axios
        .post("admin/newCategory", formData)
        .then((res) => {
          toast.success('Category Successfully Added!', {
            position: 'top-right',
            autoClose: 3000,
          });
          setCategoryName("");
          setCategoryDesc("");
          setCategoryImg(null);
          getCategory();
        })
        .catch((error) => {
          toast.error('Failed to add category. Please check your network connection.', {
            position: 'top-right',
            autoClose: false, // Keep the toast open until manually closed
          });
        });
    }
  };
  const handleDelete = async (id) => {
    await axios
      .post(`admin/deleteCategory/${id}`)
      .then((res) => {
        toast.success('Category Successfully Deleted!', {
          position: 'top-right',
          autoClose: 3000,
        });
        getCategory();
      })
      .catch((error) => {
        toast.error('Failed to delete category. Please check your network connection.', {
          position: 'top-right',
          autoClose: false, // Keep the toast open until manually closed
        });
      });
  };

  const getCategory = async () => {
    await axios
      .get("admin/listCategory")
      .then((res) => {
        setCategory(res.data);
      })
      .catch((error) => {
        toast.error('Failed to fetch categories. Please check your network connection.', {
          position: 'top-right',
          autoClose: false, 
        });
      });
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    const getRestaurant = async () => {
      try {
        const response = await axios.get("admin/listRestaurant");
        setRestaurant(response.data);
      } catch (error) {
        console.error("Error fetching restaurants:", error);
        toast.error("Failed to fetch restaurants. Please check your network connection.", {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    };
    getRestaurant();
  }, []);
  return (
    <>
      <Button onClick={handleShowModal}>Create Category</Button>
      <Modal show={showModal} onHide={handleCloseModal} style={{ top: '-20%' }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            method="POST"
            style={{ width: "80%" }}
            encType="multipart/form-data"
          >
            <ToastContainer />
            <Form.Group>
              <Form.Label className="fw-bold">Select Restaurant</Form.Label>
              <Form.Select
                value={restaurantId}
                onChange={(e) => setRestaurantId(e.target.value)}
                aria-label="select restaurant"
                size="lg"
                className="form-select"
              >
                <option value="">Select Restaurant</option>
                {restaurant.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item?.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                className="form-control-lg"
                id="cID"
                name="name"
                type="text"
                placeholder="Enter Category Name"
                value={categoryName}
                required
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Category Description</Form.Label>
              <Form.Control
                className="form-control-lg"
                id="cID"
                name="description"
                type="text"
                placeholder="Enter Category Description"
                value={categoryDesc}
                required
                onChange={(e) => setCategoryDesc(e.target.value)}
              />
            </Form.Group>
            <FormGroup>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                onChange={(e) => setCategoryImg(e.target.files[0])}
                type="file"
                name="photo"
              />
            </FormGroup>
            <Form.Group className="m-3">
              <Button type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Lists of Category</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">ID</th>
                  <th className="border-0">Name</th>
                  <th className="border-0">Description</th>
                  <th className="border-0">Image</th>
                  <th className="border-0">Restaurant</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {category.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.description.slice(0, 10)}</td>
                    <td>
                      <img
                        src={`https://api.medafdelivery.com/${item.photo}`}
                        alt="Item"
                        style={{ width: "100px" }}
                      />
                    </td>
                    <td>{item?.restaurant?.name}</td>
                    <td>
                      <Button
                        color="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>

        </Card>
      </Col>
    </>
  );
};

export default Category;
