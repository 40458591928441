import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Partners = () => {

  const [partners, setPartners] = useState([]);
  const accessToken = useSelector((state) => state.auth.user);
  const getPartners = async () => {
    try {
      const apiUrl = "https://api.medafdelivery.com/api/admin/listPartner";
      // Check if accessToken is available
      if (!accessToken) {
        throw new Error("Access token is not available.");
      }
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': accessToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        // Handle non-successful responses here, e.g., if the status code is not in the 200 range.
        throw new Error("Failed to fetch Partners. Please check your network connection.");
      }

      const data = await response.json();
      setPartners(data);
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: false,
      });
    }
  }
  useEffect(() => {
    getPartners();
  }, []);

  const handleDelete = async (id) => {
    try {
      console.log(id);
      const response = await axios.post(`https://api.medafdelivery.com/api/admin/deletePartner/${id}`);

      // Display a success toast
      toast.success('Partner deleted successfully!', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });

      console.log(response.data);
      getDrivers();
    } catch (error) {
      console.error("Error deleting Partner:", error);

      // Display an error toast
      toast.error('Failed to delete Partner. Please try again later.', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });
    }
  };

  return (
    <>

      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Lists of Partners</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">ID</th>
                  <th className="border-0">Restaurant Name</th>
                  <th className="border-0">About</th>
                  <th className="border-0">Address</th>
                  <th className="border-0">Manager Name</th>
                  <th className="border-0">Manager Phone</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {partners.map((item, index) => (
                  <tr key={item.id}> {/* Keep item.id as key for React's reconciliation */}
                    <td>{index + 1}</td> {/* Output the index + 1 as the row number */}
                    <td>{item.fullName} {item.lName}</td>
                    <td>{item.about}</td>
                    <td>{item.address}</td>
                    <td>{item.managerName}</td>
                    <td>{item.managerPhone}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.isVerified}
                        onChange={() => handleVerify(item.id)}
                        style={{ width: '20px', height: '20px' }}
                      />
                      <br />
                      <Button
                        color="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* <tbody>
                {partners.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      {item.fullName} {item.lName}
                    </td>
                    <td>{item.about}</td>
                    <td>{item.address}</td>
                    <td>{item.managerName}</td>
                    <td>{item.managerPhone}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.isVerified}
                        onChange={() => handleVerify(item.id)}
                        style={{ width: '20px', height: '20px' }}
                      />
                      <br />
                      <Button
                        color="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody> */}
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

export default Partners
