import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Alert,
  FormGroup,
  Col,
  Card,
  Table,
  FormControl,
  Modal
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Menu = () => {
  const [name, setName] = useState("");
  const [deliverySite, setdeliverySite] = useState("");
  const [durationOnSite, setdurationOnSite] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState(null);
  const [price, setPrice] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [message, setMessage] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const [menus, setMenus] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [restaurant, setRestaurant] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState(null);


  const handleEditClick = (item) => {
    setEditItem(item);
    setShowModal(true)
  };


  const handleUpdateClick = async () => {
    if (!editItem) {
      return;
    }
    const formData = new FormData();
    formData.append("id", editItem.id);
    formData.append("name", name);
    formData.append("deliverySite", deliverySite);
    formData.append("durationOnSite", durationOnSite);
    formData.append("description", description);
    formData.append("photo", photo);
    formData.append("price", price);
    formData.append("isFeatured", String(isFeatured));
    formData.append("categoryId", categoryId);
    formData.append("restaurantId", restaurantId);

    try {
      const response = await axios.post("admin/updateItems", formData); // Use POST
      // Handle success and show a message
      toast.success('Menu Successfully Updated!', {
        position: 'top-right',
        autoClose: 3000,
      });
      // Clear the form fields and close the edit form/modal
      setDescription("");
      setPhoto(null);
      setPrice("");
      setIsFeatured(false);
      setEditItem(null);
      // Refresh the list of menus
      getMenus();
    } catch (error) {
      // Handle errors and show an error message
      toast.error('Failed to update the menu. Please try again later.', {
        position: 'top-right',
        autoClose: 3000,
      });
      console.error("Error updating menu:", error);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (name === "" || price === "") {
      toast.error('Failed to Set Menu. Please try again later.', {
        position: 'top-right',
        autoClose: 3000,
      });
    } else {
      formData.append("name", name)
      formData.append("deliverySite", deliverySite)
      formData.append("durationOnSite", durationOnSite)
      formData.append("description", description)
      formData.append("photo", photo);
      formData.append("price", price)
      formData.append("isFeatured", String(isFeatured));
      formData.append("categoryId", categoryId)
      formData.append("restaurantId", restaurantId)

      await axios
        .post("admin/newItems", formData)
        .then((res) => {
          setAlertVariant("success");
          toast.success('Menu Successfully Added!', {
            position: 'top-right',
            autoClose: 3000,
          });
          setDescription("");
          setPhoto(null);
          setPrice("");
          setIsFeatured(false);

          // console.log("Data submitted");
          getMenus();
        });
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.post(`admin/deleteItems/${id}`);
      console.log(response.data);
      getMenus();

      toast.success('Item has been deleted successfully!', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });
    } catch (error) {
      toast.error('Failed to delete the item. Please try again later.', {
        position: 'top-right',
        autoClose: 3000, // Adjust the duration as needed
      });
      console.error("Error deleting item:", error);
    }
  };



  const getMenus = async () => {
    try {
      const response = await axios.get("admin/listItems");
      setMenus(response.data);
    } catch (error) {
      console.error("Error fetching menus:", error);
      toast.error("Failed to fetch menus. Please check your network connection.", {
        position: 'top-right',
        autoClose: false,
      });
    }
  };

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get("admin/listCategory");
        setCategory(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Failed to fetch categories. Please check your network connection.", {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    };

    const getRestaurant = async () => {
      try {
        const response = await axios.get("admin/listRestaurant");
        setRestaurant(response.data);
      } catch (error) {
        console.error("Error fetching restaurants:", error);
        toast.error("Failed to fetch restaurants. Please check your network connection.", {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    };

    getCategory();
    getMenus();
    getRestaurant();
  }, []);

  return (
    <>
      <Button onClick={handleShowModal}>Create Menu</Button>
      <Modal show={showModal} onHide={handleCloseModal} style={{ top: '-40%', height: '100vh' }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editItem && (
            <Form
              onSubmit={handleSubmit}
              method="POST"
              encType="multipart/form-data"
            >

              <ToastContainer />
              <Form.Group >
                <Form.Label className="fw-bold">Select Category</Form.Label>
                <Form.Select
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  aria-label="select category"
                  size="lg"
                  className="form-select"
                >
                  <option value="">Select Category</option>
                  {category.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label className="fw-bold">Select Restaurant</Form.Label>
                <Form.Select
                  value={restaurantId}
                  onChange={(e) => setRestaurantId(e.target.value)}
                  aria-label="select restaurant"
                  size="lg"
                  className="form-select"
                >
                  <option value="">Select Restaurant</option>
                  {restaurant.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>Item Name</Form.Label>
                <Form.Control
                  className="form-control-lg"
                  name="name"
                  type="text"
                  placeholder="Enter Item name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Delivery Site(for Document Only)</Form.Label>
                <Form.Control
                  className="form-control-lg"
                  name="deliverySite"
                  type="text"
                  placeholder="Enter Delivery name"
                  value={deliverySite}
                  onChange={(e) => setdeliverySite(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Duration(for Doc)</Form.Label>
                <Form.Control
                  className="form-control-lg"
                  name="durationOnSite"
                  type="text"
                  placeholder="Enter Duration name"
                  value={durationOnSite}
                  onChange={(e) => setdurationOnSite(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  className="form-control-lg"
                  name="description"
                  type="text"
                  placeholder="Enter Description"
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>

              <FormGroup>
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  onChange={(e) => setPhoto(e.target.files[0])}
                  type="file"
                  name="photo"
                />
              </FormGroup>

              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  className="form-control-lg"
                  name="price"
                  type="text"
                  placeholder="Enter price"
                  value={price}
                  required
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Is Featured</Form.Label>
                <Form.Control
                  className="form-control-sm"
                  name="featured"
                  type="checkbox"
                  placeholder="Is featured"
                  required
                  onChange={(e) => setIsFeatured(e.target.checked)}
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Button type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form.Group>
              <Button variant="primary" onClick={handleUpdateClick}>Update</Button>
              <Button variant="secondary" onClick={() => setEditItem(null)}>Cancel</Button>

            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">List of Menus</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">ID</th>
                  <th className="border-0">Name</th>
                  <th className="border-0">Category</th>
                  <th className="border-0">Restaurant</th>
                  <th className="border-0">Price</th>
                  <th className="border-0">Image</th>
                  <th className="border-0">Is Featured</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {menus.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item?.Category?.name}</td>
                    <td>{item?.Restaurant?.name}</td>
                    <td>{item.price} ETB</td>
                    <td>
                      <img
                        src={`https://api.medafdelivery.com/${item.picture}`}
                        alt={item.name}
                        style={{ width: "100px" }}
                      />
                    </td>
                    <td>{item.isFeatured ? "Yes" : "No"}</td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => handleEditClick(item)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Menu;
