import Dashboard from "views/Dashboard.js";
import Category from "views/Category";
import Blog from "views/Blog";
import Restaurant from "views/Restaurant";
import Menu from "views/Menu";
import Driver from "views/Driver";
import Account from "views/Account";
import Order from "views/Order";
import Organization from "views/Organization";
import CreateOrder from "views/CreateOrder";
import Partners from "views/Partners";
import Contacts from "views/Contacts";
import ServiceType from "views/ServiceType";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/restaurant",
    name: "Restaurant",
    icon: "nc-icon nc-bank",
    component: Restaurant,
    layout: "/admin",
  },
  {
    path: "/serviceType",
    name: "Service Type",
    icon: "nc-icon nc-app",
    component: ServiceType,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Category",
    icon: "nc-icon nc-app",
    component: Category,
    layout: "/admin",
  },
  {
    path: "/menu",
    name: "Menu",
    icon: "nc-icon nc-chart-pie-36",
    component: Menu,
    layout: "/admin",
  },
  {
    path: "/create-order",
    name: "Create Order",
    icon: "nc-icon nc-paper-2",
    component: CreateOrder,
    layout: "/admin",
  },
  {
    path: "/order",
    name: "Orders",
    icon: "nc-icon nc-paper-2",
    component: Order,
    layout: "/admin",
  },
  {
    path: "/driver",
    name: "Driver",
    icon: "nc-icon nc-ambulance",
    component: Driver,
    layout: "/admin",
  },
  {
    path: "/account",
    name: "Account",
    icon: "nc-icon nc-circle-09",
    component: Account,
    layout: "/admin",
  },
  {
    path: "/partners",
    name: "Partners",
    icon: "nc-icon nc-circle-09",
    component: Partners,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "Contacts",
    icon: "nc-icon nc-circle-09",
    component: Contacts,
    layout: "/admin",
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "nc-icon nc-app",
    component: Blog,
    layout: "/admin",
  },
];

export default dashboardRoutes;
