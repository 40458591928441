import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Maps from './Maps';
import axios from 'axios';

const DriverSelectorModal = ({ show, onHide, setSelectedDriver, order }) => {

    const [selectedDriver, updateSelectedDriver] = useState(null);

    const handleDriverSelection = (driver) => {
        setSelectedDriver(driver);
        updateSelectedDriver(driver);
    };

    const handleAssignDriver = async () => {
        onHide();
        if (!selectedDriver) {
            alert('Please select a driver before assigning.');
            return;
        }

        try {
            const driverId = selectedDriver?.ID;
            const response = await axios.post(`admin/orders/${order}/assignDriver`, {
                driverId: driverId,
            });

            if (response.data.success) {
                alert(`Driver assigned! \nDriver Name: ${selectedDriver?.title} \nDriver Phone: ${selectedDriver?.phone} \nDriver ID: ${driverId}\nOrder ID: ${order}`);
                setSelectedDriver(null);
                console.log('API Response:', response.data);
            } else {
                alert('Failed to assign driver: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error assigning driver:', error);
            alert('Failed to assign driver: ' + error.message);
        }


    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Select a Driver</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ maxHeight: '300px', maxWidth: '600px', overflow: 'auto' }}>
                    <Maps setSelectedDriver={handleDriverSelection} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAssignDriver}>
                    Assign Driver
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DriverSelectorModal;
