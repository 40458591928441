import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { Col, Card, Table, Button, FormGroup, FormControl, Alert, Form } from "react-bootstrap";
import "./AddMenu.css";
import Menu from "./Menu";
import SingleMenu from "./SingleMenu";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddMenu = () => {
    const { restaurantId } = useParams();
    const [restaurant, setRestaurant] = useState(null);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [menuItem, setMenuItem] = useState("");
    const [menus, setMenus] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [description, setDescription] = useState("");
    const [photo, setPhoto] = useState("");
    const [price, setPrice] = useState("");
    const [category, setCategory] = useState([]);
    const [message, setMessage] = useState(false);
    const [alertVariant, setAlertVariant] = useState("success");

    useEffect(() => {
        const fetchRestaurant = async () => {
            try {
                const response = await axios.get(
                    `admin/getRestaurant/${restaurantId}`
                );
                setRestaurant(response.data);
                setName(response.data.name);
                setAddress(response.data.address);
                setPhone(response.data.phone);
                setEmail(response.data.email);
                setIsFeatured(response.data.isFeatured);
            } catch (error) {
                console.error("Error fetching restaurant:", error);
            }
        };

        fetchRestaurant();
    }, [restaurantId]);

    //   

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get("admin/listCategory");
                setCategory(response.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    //   const handleUpdateRestaurant = async (e) => {
    //     e.preventDefault();
    //     try {
    //       const response = await axios.put(
    //         `admin/updateRestaurant/${restaurantId}`,
    //         { name, address, phone, email, isFeatured }
    //       );
    //       setRestaurant(response.data);
    //       console.log("Restaurant updated:", response.data);
    //     } catch (error) {
    //       console.error("Error updating restaurant:", error);
    //     }
    //   };




    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("categoryId", categoryId);
            formData.append("name", name);
            formData.append("description", description);
            formData.append("photo", photo);
            formData.append("price", price);
            formData.append("isFeatured", isFeatured);

            await axios.post("admin/addMenu", formData);
            toast.success('Menu Successfully Added!', {
                position: 'top-right',
                autoClose: 3000,
            });
            setName("");
            setCategoryId("");
            setDescription("");
            setPhoto("");
            setPrice("");
            setIsFeatured(false);
        } catch (error) {
            toast.error('Failed to Add Menu. Please try again later.', {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };

    const handleDelete = async (menuId) => {

    };

    return (
        <div className="add-menu-container">
            <div className="restaurant-info">
                <Link to="/admin/restaurant">
                    <i className="fas fa-arrow-left"></i> Back
                </Link>
                <h1>Organization Information</h1>
                {restaurant ? (
                    <form>
                        {/* Restaurant form */}
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="address" className="form-label">
                                Address
                            </label>
                            <input
                                type="text"
                                id="address"
                                className="form-control"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">
                                Phone
                            </label>
                            <input
                                type="text"
                                id="phone"
                                className="form-control"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                                Email
                            </label>
                            <input
                                type="text"
                                id="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3 form-check">
                            <input
                                type="checkbox"
                                id="isFeatured"
                                className="form-check-input"
                                checked={isFeatured}
                                onChange={(e) => setIsFeatured(e.target.checked)}
                            />
                            <label htmlFor="isFeatured" className="form-check-label">
                                Is Featured
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Update Restaurant
                        </button>
                    </form>
                ) : (
                    <p>Loading restaurant information...</p>
                )}
            </div>

            <div className="menu-list">
                <SingleMenu rId={restaurantId}/>
            </div>
        </div>
    );
};

export default AddMenu;
