import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Navbar, Container, Nav, Button, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import routes from 'routes.js';

function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const cartItems = useSelector(state => {
    let totalQuantity = 0;
    state.auth.cartItems.forEach(item => {
      totalQuantity += item.quantity;
    });
    return totalQuantity;
  });
  //const cartItems = useSelector((state) => state.auth.cartItems);

  const mobileSidebarToggle = e => {
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    var node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    // Add any additional logout-related logic here (e.g., redirecting to a login page)
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand href="#home" onClick={e => e.preventDefault()} className="mr-2">
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar></Nav>
          <Nav className="ml-auto" navbar>
            <Nav.Item>
              <Nav.Link className="m-0" href="#pablo" as={Link} to="/cart">
                <span className="nc-icon nc-cart-simple lg">
                  {cartItems > 0 && (
                    <Badge pill variant="primary">
                      {cartItems}
                    </Badge>
                  )}
                </span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link className="m-0" href="#pablo" onClick={e => e.preventDefault()}>
                <span className="no-icon">
                  <Button className="btn btn-sm btn-warning" onClick={handleLogout}>
                    Logout
                  </Button>
                </span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
