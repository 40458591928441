import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Button,
  Alert,
  FormGroup,
  Container,
  Col,
  Row,
  Card,
  Table,
  FormControl,
  Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Modal.css";

const Restaurant = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState("");
  const [featured, setFeatured] = useState(false);
  //newly added
  const [isOpen, setIsOpen] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeId, setServiceTypeId] = useState("");

  const [showModal, setShowModal] = useState(false);
  const accessToken = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [restaurants, setRestaurants] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [editingRestaurantId, setEditingRestaurantId] = useState(null);

  const handleEditClick = (restaurant) => {
    setName(restaurant.name);
    setAddress(restaurant.address);
    setEmail(restaurant.email);
    // setPassword(""); // We usually don't edit passwords this way
    setPhone(restaurant.phone);
    setFeatured(restaurant.isFeatured);

    setIsOpen(restaurant.isOpen)
    setLatitude(restaurant.latitude)
    setLongitude(restaurant.longitude)
    setOpeningTime(restaurant.openingTime)
    setClosingTime(restaurant.closingTime)
    setServiceType(restaurant.serviceTypeId)
    // setPhoto(""); // Handle photo edit properly
    setIsEditing(true);
    setEditingRestaurantId(restaurant.id);
    handleShowModal();
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;

    const config = {
      headers: {
        'Authorization': accessToken, // Assuming you're using Bearer tokens
      },
    };

    const formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('isFeatured', String(featured));
    formData.append('latitude', latitude)
    formData.append('longitude', longitude)
    formData.append('openingTime', openingTime)
    formData.append('closingTime', closingTime)
    formData.append('isOpen', String(isOpen))
    formData.append('serviceTypeId', serviceTypeId)
    if (photo) formData.append('photo', photo); // Only add photo if it's present

    // Decide whether we're adding or editing based on isEditing
    try {
      if (isEditing) {
        // Prepare the data for updating the restaurant
        // Note: Handle the password and photo updates separately for security and file handling reasons
        const updatedRestaurant = {
          name,
          address,
          email,
          phone,
          isFeatured: featured,
          // Assuming 'photo' holds the file to be uploaded, handle this in your actual API call
          // photo, 
        };

        try {
          // Make the POST request to the update endpoint with the restaurant ID
          const response = await axios.post(`https://api.medafdelivery.com/api/admin/updateRestaurant/${editingRestaurantId}`, updatedRestaurant, {
            headers: {
              'Authorization': accessToken, // Assuming you're using Bearer tokens
              // 'Content-Type': 'multipart/form-data', // Use this if you are sending files
            },
          });

          // If the request is successful, display a success message
          toast.success('Restaurant Successfully Updated!', {
            position: 'top-right',
            autoClose: 3000,
          });

          // Close the modal and refresh the restaurant list
          handleCloseModal();
          getRestaurant();
        } catch (error) {
          // Handle errors by displaying an appropriate message to the user
          let errorMessage = 'Failed to update restaurant.';
          if (error.response) {
            // The server responded with a non-2xx status code
            errorMessage += ` Server responded with: ${error.response.status}`;
            if (error.response.data) {
              errorMessage += ` - ${error.response.data.message || JSON.stringify(error.response.data)}`;
            }
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage += ' No response received.';
          } else {
            // An error occurred in setting up the request
            errorMessage += ` ${error.message}`;
          }
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: false,
          });
        }
      } else {
        // Add new restaurant
        if (name === "" || email === "") {
          toast.error("Failed to Add Restaurant. Please fill in all required fields.", {
            position: "top-right",
            autoClose: 3000,
          });
          return; // Exit if required fields are missing
        }
        formData.append('password', password); // Add password only when creating a new entry
        response = await axios.post("admin/newRestaurant", formData, config);
      }

      // Handle response from either add or edit
      toast.success(`Restaurant Successfully ${isEditing ? 'Updated' : 'Added'}!`, {
        position: "top-right",
        autoClose: 3000,
      });
      handleCloseModal();
      getRestaurant();
    } catch (error) {
      let errorMessage = `Failed to ${isEditing ? 'update' : 'add'} restaurant.`;

      if (error.response) {
        // Server responded with a status code that falls out of the range of 2xx
        errorMessage += ` Server responded with: ${error.response.status}`;
        if (error.response.data) {
          errorMessage += ` - ${error.response.data.message || JSON.stringify(error.response.data)}`;
        }
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage += ' No response received.';
      } else {
        // Something happened in setting up the request that triggered an error
        errorMessage += ` ${error.message}`;
      }

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: false,
      });
    }
  };

  const handleDelete = async (id) => {
    await axios
      .post(`admin/deleteRestaurant/${id}`)
      .then((res) => {
        toast.success("Restaurant Successfully Deleted!", {
          position: "top-right",
          autoClose: 3000,
        });
        getRestaurant();
      })
      .catch((error) => {
        toast.error(
          "Failed to delete restaurant. Please check your network connection.",
          {
            position: "top-right",
            autoClose: false,
          }
        );
      });
  };

  const getRestaurant = async () => {
    try {
      const apiUrl = "https://api.medafdelivery.com/api/admin/listRestaurant";

      // Check if accessToken is available
      if (!accessToken) {
        throw new Error("Access token is not available.");
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: accessToken,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // Handle non-successful responses here, e.g., if the status code is not in the 200 range.
        throw new Error(
          "Failed to fetch restaurants. Please check your network connection."
        );
      }

      const data = await response.json();
      setRestaurants(data);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: false,
      });
    }
  };

  const handleRestaurantClick = (restaurantId) => {
    history.push(`/add-menu/${restaurantId}`);
  };

  useEffect(() => {

    const getServiceType = async () => {
      try {
        const response = await axios.get("admin/listServiceType");
        setServiceType(response.data);
      } catch (error) {
        console.error("Error fetching service type:", error);
        toast.error("Failed to fetch service type. Please check your network connection.", {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    };
    getServiceType();
  }, []);

  useEffect(() => {
    getRestaurant();
  }, []);

  return (
    <>
      <Button onClick={handleShowModal}>Create Restaurant</Button>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="modal-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} method="POST" style={{ width: "80%" }}>
            <Form.Group>

              <Form.Group>
                <Form.Label className="fw-bold">Select Service Type</Form.Label>
                <Form.Select
                  value={serviceTypeId}
                  onChange={(e) => setServiceTypeId(e.target.value)}
                  aria-label="Select Service Type"
                  size="lg"
                  className="form-select"
                >
                  <option value="">Select Service Type</option>
                  {serviceType.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Label>Name</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="name"
                type="text"
                placeholder="Enter Organization Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="address"
                type="text"
                placeholder="Enter Address"
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="email"
                type="email"
                placeholder="Enter Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="password"
                type="text"
                placeholder="Enter Password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="phone"
                type="text"
                placeholder="Enter Phone"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="latitude"
                type="text"
                placeholder="Enter Latitude"
                value={latitude}
                required
                onChange={(e) => setLatitude(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                className="form-control-lg"
                name="longitude"
                type="text"
                placeholder="Enter Longitude"
                value={longitude}
                required
                onChange={(e) => setLongitude(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Is Open</Form.Label>
              <Form.Control
                className="form-control-sm"
                name="isOpen"
                type="checkbox"
                placeholder="Is Open"
                required
                checked={isOpen}
                onChange={(e) => setIsOpen(e.target.checked)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Opening Time</Form.Label>
              <Form.Control
                className="form-control-sm"
                name="openingTime"
                type="time"
                placeholder="Opening Time"
                required
                value={openingTime}
                onChange={(e) => setOpeningTime(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Closing Time</Form.Label>
              <Form.Control
                className="form-control-sm"
                name="closingTime"
                type="time"
                placeholder="ClosingT ime"
                required
                value={closingTime}
                onChange={(e) => setClosingTime(e.target.value)}
              />
            </Form.Group>

            <FormGroup>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                onChange={(e) => setPhoto(e.target.files[0])}
                type="file"
                name="photo"
              />
            </FormGroup>

            <Form.Group>
              <Form.Label>Is Featured</Form.Label>
              <Form.Control
                className="form-control-sm"
                name="featured"
                type="checkbox"
                placeholder="Is featured"
                required
                checked={featured}
                onChange={(e) => setFeatured(e.target.checked)}
              />
            </Form.Group>
            <Form.Group className="m-3">
              <ToastContainer />
              <Button type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">List of Organizations</Card.Title>
          </Card.Header>
          {!restaurants ? (
            <h1>No Organizations found</h1>
          ) : (
            <>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">ID</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">Address</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Phone</th>
                      <th className="border-0">Latitide</th>
                      <th className="border-0">Longtude</th>
                      <th className="border-0">Opening Time</th>
                      <th className="border-0">Closing Time</th>
                      <th className="border-0">Service Type</th>
                      <th className="border-0">Is Open</th>
                      <th className="border-0">Featured</th>
                    </tr>
                  </thead>
                  <tbody>
                    {restaurants.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRestaurantClick(item.id)}
                        >
                          {item.name}
                        </td>
                        <td>{item.address}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.latitude}</td>
                        <td>{item.longitude}</td>
                        <td>{item.openingTime}</td>
                        <td>{item.closingTime}</td>
                        <td>{item?.serviceType?.name}</td>
                        <td>{item.isOpen ? "Yes" : "No"}</td>
                        <td>{item.isFeatured ? "Yes" : "No"}</td>
                        <td>
                          <Button
                            variant="info"
                            onClick={() => handleEditClick(item)}
                          >
                            Edit
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => handleDelete(item.id)}
                          >
                            x
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </>
          )}
        </Card>
      </Col>
    </>
  );
};

export default Restaurant;
