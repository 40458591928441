import React, { useState, useEffect } from 'react';
import { Card, Row } from 'react-bootstrap';
import axios from 'axios';
import DriverSelectorModal from './DriverSelectorModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OutForDeliveryOrder = () => {
    const [outForDeliveryOrders, setOutForDeliveryOrders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    

    useEffect(() => {
        fetchOutForDeliveryOrders();
    }, []);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const fetchOutForDeliveryOrders = async () => {
        try {
          const response = await axios.get("admin/getOutForDeliveryOrder");
          const menuItemsResponse = await axios.get("admin/listItems");
      
          const outForDeliveryOrderData = response.data;
          const menuItemsData = menuItemsResponse.data;
      
          setOutForDeliveryOrders(
            outForDeliveryOrderData.map(order => ({
              ...order,
              menuItems: order.OrderedItems.map(orderItem => {
                const menuItem = menuItemsData.find(menuItem => menuItem.id === orderItem.menuId);
                return menuItem ? { ...orderItem, menuItem } : null;
              }).filter(Boolean)
            }))
          );
      
        //   toast.success('Out for delivery orders have been successfully fetched!', {
        //     position: 'top-right',
        //     autoClose: 3000, // Adjust the duration as needed
        //   });
        } catch (error) {
          toast.error('Failed to fetch out for delivery orders. Please try again later.', {
            position: 'top-right',
            autoClose: 3000, // Adjust the duration as needed
          });
          console.error("Error fetching out for delivery orders and menu items:", error);
        }
      };
      

    const handleAssignDriver = async (orderId, selectedDriver) => {
        if (!selectedDriver) {
            alert('Please select a driver from the map first.');
            return;
        }
        console.log("Incoming Driver ID: ", selectedDriver)
        setShowModal(false);

        try {
            const driverId = selectedDriver.ID;
            alert("Driver ID from OutForDelivery", driverId)
            await axios.post(`admin/orders/${orderId}/on-the-way-delivery`);
            const updatedOrders = outForDeliveryOrders.map(order => {
                if (order.id === orderId) {
                    return {
                        ...order,
                        status: "out for delivery",
                        DriverId: driverId
                    };
                }
                return order;
            });
            setBeingPreparedOrders(updatedOrders);
            fetchBeingPreparedOrders();
            toast.success('Order status Successfully Added!', {
                position: 'top-right',
                autoClose: 3000,
              });
        } catch (error) {
            toast.error('Failed to Set Order status. Please try again later.', {
                position: 'top-right',
                autoClose: 3000,
              });
        }
    };

    const formatUpdatedAt = (updatedAt) => {
        try {
          // Create a new Date object from the updatedAt date string
          const date = new Date(updatedAt);
    
          // Get the date components
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
    
          // Format the date as "yyyy-MM-dd HH:mm:ss"
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        } catch (error) {
          console.error("Error formatting updatedAt:", error);
          return "Error";
        }
      };

    return (
        <>
            <strong># Ready for Delivery Orders</strong>
            <br /><br />
            
            <Row>
                {!outForDeliveryOrders ? (
                    <h1>No Out for Delivery Orders</h1>
                ) : (
                    outForDeliveryOrders.map(order => (
                        <div className="col-xl-6 col-sm-6 mb-6" key={order.id}>
                            <Card className="o-hidden h-100">
                                <Card.Header>
                                    <div className="d-flex justify-content-between">
                                        <p><strong>#Order ID:</strong> {order.OrderTrackingNumber}</p>
                                        <p><strong>Order Date:</strong> {formatUpdatedAt(order.updatedAt)}</p>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {order.menuItems.map(orderItem => (
                                        <div key={orderItem.menuId}>
                                            <div className="d-flex justify-content-between">
                                                <img src={`https://api.medafdelivery.com/${orderItem.menuItem.picture}`} width="50px" className="rounded-circle" alt="img.." />
                                                <p>{orderItem.quantity} x {orderItem.menuItem?.name} From {orderItem.menuItem.Restaurant?.name}</p>
                                                <p><strong>Payment: </strong>{order.PaymentMethod}</p>
                                            </div>

                                        </div>
                                    ))}
                                </Card.Body>
                                <Card.Footer>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span>order:{order.OrderStatus} ID: {order.id}</span>
                                            <p><strong>Customer Name:</strong> {order.CustomerName}</p>
                                            <p><strong>Order Price:</strong> ETB {order.TotalAmount}</p>
                                            <p><strong>Customer Num:</strong> {order.ContactInfo}</p>
                                            <p><strong>Delivery Address:</strong> {order.deliveryAdress}</p>
                                            <p><strong>Driver Name:</strong> {order?.DriverId} </p>
                                        </div>
                                        <div className="d-flex justify-content-start ms-2 align-items-sm-center">
                                            <DriverSelectorModal
                                                show={showModal}
                                                onHide={() => setShowModal(false)}
                                                setSelectedDriver={setSelectedDriver}
                                                order={order.id}
                                            />
                                            {/* ... existing code */}
                                            <button
                                                className="btn btn-sm btn-success"
                                                onClick={() => {
                                                    handleShowModal();
                                                }}
                                            >
                                                Select Driver
                                            </button>
                                            <ToastContainer />
                                            <button className="btn btn-sm btn-success" onClick={() => handleAssignDriver(order.id)}>Assign Driver</button>
                                        </div>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    ))
                )}
            </Row>
        </>
    )
}

export default OutForDeliveryOrder
